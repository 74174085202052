import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import React from 'react';
import './App.css';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import SavingsCalculator from "./components/calculators/savings/SavingsCalculator";
import Home from './components/Home';
import Navigation from "./components/layouts/header/Navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Sidebar from "./components/layouts/sidebar/Sidebar";
import Footer from "./components/layouts/footer/Footer";

function App() {
    return (
        <BrowserRouter>
            <div>
                <Navigation/>
                <Container className="App-content">
                    <Row>
                        {/*<Col md={2} className="themed-grid-col-sidebar">Left sidebar</Col>*/}
                        <Col md={10} className="themed-grid-col">
                            <Switch>
                                <Route path="/" component={Home} exact/>
                                <Route path="/savings-calculator" component={SavingsCalculator}/>
                                <Route component={Error}/>
                            </Switch>
                        </Col>
                        <Col md={2} className="themed-grid-col-sidebar">
                            <Sidebar/>
                        </Col>
                    </Row>
                </Container>
                <Footer/>
            </div>
        </BrowserRouter>
    );
}

export default App;


