import React from 'react';
import CardDeck from "react-bootstrap/CardDeck";
import CardComponent from "./CardComponent";

function Home() {
  return (
    <div>
      <h1>Financial Calculators</h1>
      {/*<Jumbotron fluid>*/}
      {/*  <Container>*/}
      {/*    <h1>Financial Calculators</h1>*/}
      {/*    <p>*/}
      {/*      Here is a list of our financial calculators.*/}
      {/*    </p>*/}
      {/*  </Container>*/}
      {/*</Jumbotron>*/}
      <CardDeck className="cards-row">
        <CardComponent
          title="Savings Calculator"
          text="Check how your savings grow over time"
          footer="Last updated: March 2020"
          location="/savings-calculator"
        />
        <CardComponent
          title="Mortgage Payoff Calculator"
          text="Estimate how fast you can pay off your mortgage"
          footer="Available soon"/>
        <CardComponent
          title="Rent vs Buy Calculator"
          text="Should I rent or buy a home?"
          footer="Available soon"/>
        <CardComponent
          title="Home Affordability Calculator"
          text="How much house can I afford?"
          footer="Available soon"/>
      </CardDeck>

      <CardDeck className="cards-row">
        <CardComponent
          title="Lease vs Buy Calculator"
          text="Should I lease or buy a car?"
          footer="Available soon"/>
        <CardComponent
          title="Car Affordability Calculator"
          text="How much car can I afford?"
          footer="Available soon"/>
        <CardComponent
          title="Retirement Calculator"
          text="How much should I save to retire?"
          footer="Available soon"/>
        <CardComponent
          title="Social Security Calculator"
          text="Calculate your Social Security payments"
          footer="Available soon"/>
      </CardDeck>

    </div>
  );
};

export default Home;