import moment from "moment";

/**
 * Class to store the results of the savings calculation
 */
export class SavingResults {
    constructor(initialAmounts, contributions, interest, totalSavings) {
        this.initialAmounts = initialAmounts; // An array with the same value repeated multiple times
        this.interest = interest;
        this.contributions = contributions;
        this.totalSavings = totalSavings; // totalSavings is contributions + interest
    }
}

export function generateLabels(investmentLength, investmentLengthUnit, startFromCurrentDate) {
    let labels = [];
    if (startFromCurrentDate) {
        if (investmentLengthUnit === "years") {
            for (let i = 0; i < investmentLength; i++) {
                let current = moment().add(i, 'years');
                labels.push(current.format('MMM YYYY'));
            }
        }
        else { // months
            for (let i = 0; i < investmentLength; i++) {
                let current = moment().add(i, 'months');
                labels.push(current.format('MMM YYYY'));
            }
        }
    }
    else {
        for (let i = 1; i <= investmentLength; i++) {
            labels.push(i);
        }
    }
    return labels;
}

// Interest rate is APR
// The interest compound is calculated monthly

export function calculateSavings(initialAmount, monthlyContribution, interestRate, investmentLength, investmentLengthUnit) {

    let interestRatePerMonth = interestRate / 12; // interest rate per month

    let compoundPeriods = 12;
    if (investmentLengthUnit === "months") {
        compoundPeriods = 1;
    }


    // Array with the initial amount, repeated multiple times
    let initialAmounts = [];
    //initialAmounts.push(initialAmount);
    //Array with the periodic contributions, excluding initial amount and interest
    let contributions = [];
    //contributions.push(0);
    // Array with the accumulated interest
    let interest = [];
    //interest.push(0);
    // Array of total savings per year (it includes the interest)
    let savings = [];
    //savings.push(initialAmount);

    let accumulatedContributions = 0;
    let accumulatedInterest = 0;
    let accumulatedSavings = initialAmount;

    for (let i = 0; i < investmentLength; i++) {
        for (let j = 0; j < compoundPeriods; j++) {
            accumulatedContributions = accumulatedContributions + monthlyContribution;

            let interest = (accumulatedSavings * interestRatePerMonth) / 100;
            accumulatedInterest = accumulatedInterest + interest;

            accumulatedSavings = initialAmount + accumulatedContributions + accumulatedInterest;
        }
        initialAmounts.push(initialAmount);
        contributions.push(accumulatedContributions);
        interest.push(accumulatedInterest);
        savings.push(accumulatedSavings);

    }
    let savingResults = new SavingResults(initialAmounts, contributions, interest, savings);
    return savingResults;
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getXLabel(investmentLengthUnit) {
    if (investmentLengthUnit === "months") {
        return "Month";
    }
    else {
        return "Year";
    }
}

export function getTotalSavingsDateString(label, investmentLengthUnit) {
    if (isNaN(label)) { // It is not a number -> It is a date in the format e.g. Dec 2020
        return "by " + label;
    }
    else {
        let suffix = investmentLengthUnit === "months"? "months" : "years";
            return "in " + label + " " + suffix;
    }
}

/**
 *
 * @param amount
 * @param currencySymbol
 * @param decimalPos
 * @returns {string}
 */
export function formatAsCurrency(amount, currencySymbol, decimalPos) {
    return currencySymbol + numberWithCommas(amount.toFixed(decimalPos));
}