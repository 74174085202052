import React, {useEffect, useState} from 'react';
import SavingsCalculatorResults from "./SavingsCalculatorResults";
import {Button, Col, Container, Form, Row} from "react-bootstrap";

// Note that this is a function component
// (see https://reactjs.org/docs/components-and-props.html#function-and-class-components)
function SavingsCalculator() {

    const drawChartsWhenComponentDidMount = false; // Use it to enable/disable the first chart rendering
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [validated, setValidated] = useState(false);
    //const [isValid, setIsValid] = useState(false);
    const [updateResults, setUpdateResults] = useState(false);

    const [initialAmountValue, setInitialAmountValue] = useState("3000");
    const [monthlyContributionValue, setMonthlyContributionValue] = useState("100");
    const [interestRateValue, setInterestRateValue] = useState("1.5");
    const [investmentLengthValue, setInvestmentLengthValue] = useState("20"); // 20 is the initial value
    const [investmentLengthUnitValue, setInvestmentLengthUnitValue] = useState("years"); // 20 is the initial value
    const [startFromCurrentDateValue, setStartFromCurrentDateValue] = useState(true);

    // Similar to componentDidMount. I use it to generate the graphs the first time the component is rendered
    useEffect(() => {
        if (drawChartsWhenComponentDidMount) {
            if (isFirstRender) {
                // Generates the graph the first time, with the default values
                //setIsValid(true);
                setUpdateResults(true);
                setIsFirstRender(false);
            }
        }
        // The reason to include these values in the array is at
        // https://reactjs.org/docs/hooks-effect.html#tip-optimizing-performance-by-skipping-effects (see "Note")
    }, [isFirstRender, drawChartsWhenComponentDidMount]);

    function setValidationAndUpdateStatus(event, updateWhenValid) {
        if (event.currentTarget.checkValidity() === false) {
            //setIsValid(false);
            setUpdateResults(false);
        }
        else {
            //setIsValid(true);
            setUpdateResults(updateWhenValid);
        }
        setValidated(true);
    };

    function handleSubmit(event) {
        // prevent default submit behavior
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidationAndUpdateStatus(event, true);
    };

    function handleOnChange(setFunction, event) {
        setFunction(event.target.value);
        setValidationAndUpdateStatus(event, false);
    };

    function handleOnChangeCheckBox(setFunction, event) {
        setFunction(event.target.checked);
        setValidationAndUpdateStatus(event, true);
    };

    function handleOnChangeSelect(setFunction, event) {
        setFunction(event.target.value);
        setValidationAndUpdateStatus(event, true);
    };

    return (
        <div>
            <h1>Savings Calculator</h1>
            <h4>Check how your savings grow over time</h4>
            <Container>
                <Row>
                    <Col md={4} className="calc-grid-form">
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Form.Group controlId="formInitialAmount">
                                <Form.Label>Initial amount ($)</Form.Label>
                                <Form.Control
                                    type="number"
                                    step="any"
                                    value={initialAmountValue}
                                    onBlur={e => handleSubmit(e)}
                                    onChange={e => handleOnChange(setInitialAmountValue, e)}
                                    min={0}
                                    max={1000000000}
                                    placeholder="Enter your initial savings"
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid amount.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formMonthlyContribution">
                                <Form.Label>Monthly contribution ($)</Form.Label>
                                <Form.Control
                                    type="number"
                                    step="any"
                                    value={monthlyContributionValue}
                                    onBlur={e => handleSubmit(e)}
                                    onChange={e => handleOnChange(setMonthlyContributionValue, e)}
                                    min={0}
                                    max={1000000000}
                                    placeholder="Enter your monthly contribution"
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid amount.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formInterestRate">
                                <Form.Label>Annual interest rate (%)</Form.Label>
                                <Form.Control
                                    type="number"
                                    step="any"
                                    value={interestRateValue}
                                    onBlur={e => handleSubmit(e)}
                                    onChange={e => handleOnChange(setInterestRateValue, e)}
                                    min={0}
                                    max={1000}
                                    placeholder="Enter an interest rate"
                                    required
                                />
                                <Form.Text className="text-muted">
                                    The interest will be compounded monthly.
                                </Form.Text>
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid interest rate.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formInvestmentLength">
                                <Row><Col sm={12}>Time to grow</Col></Row>
                                <Row>
                                    <Col sm={5}>
                                        <Form.Control
                                            type="number"
                                            value={investmentLengthValue}
                                            onBlur={e => handleSubmit(e)}
                                            onChange={e => handleOnChange(setInvestmentLengthValue, e)}
                                            min={0}
                                            max={200}
                                            placeholder="Enter a number of years"
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid number of years.
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col sm={7}>
                                        <Form.Group controlId="formSelectGrowUnit">
                                            <Form.Control
                                                as="select"
                                                value={investmentLengthUnitValue}
                                                onChange={e => handleOnChangeSelect(setInvestmentLengthUnitValue, e)}>
                                                <option value="years">Years</option>
                                                <option value="months">Months</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {/*<Form.Label as="legend" column>&nbsp;</Form.Label>*/}
                                        <Form.Check
                                            custom
                                            inline
                                            label="Start from current date"
                                            type="checkbox"
                                            id={'custom-inline-checkbox-1'}
                                            checked={startFromCurrentDateValue}
                                            onChange={e => handleOnChangeCheckBox(setStartFromCurrentDateValue, e)}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Button type="submit" disabled={updateResults}>Calculate</Button>
                        </Form>
                    </Col>
                    <Col md={8}>
                        {/*{!isValid && <div className="enterValuesMessage">Enter the values on the left</div>}*/}
                        {/*isValid &&*/ <SavingsCalculatorResults
                            updateResults={updateResults}
                            initialAmount={parseFloat(initialAmountValue)}
                            monthlyContribution={parseFloat(monthlyContributionValue)}
                            interestRate={parseFloat(interestRateValue)}
                            investmentLength={parseInt(investmentLengthValue)}
                            investmentLengthUnit={investmentLengthUnitValue}
                            startFromCurrentDate={startFromCurrentDateValue}
                        />}
                    </Col>
                </Row>
            </Container>
        </div>
    );

};
export default SavingsCalculator;