import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import {calculateSavings, generateLabels, formatAsCurrency, getTotalSavingsDateString} from './utils.js';
import SavingsCalculatorBarChart from "./SavingsCalculatorBarChart";
import './savings.css';
import ListGroup from "react-bootstrap/ListGroup";
import Table from "react-bootstrap/Table";

export default class SavingsCalculatorResults extends React.Component {

    // Re-render the component only when needed
    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.updateResults;
    }

    render() {

        // Calculate savings results
        let savingResults = calculateSavings(this.props.initialAmount, this.props.monthlyContribution,
            this.props.interestRate, this.props.investmentLength, this.props.investmentLengthUnit);
        // Generate labels for the X axis
        let labels = generateLabels(this.props.investmentLength, this.props.investmentLengthUnit,
            this.props.startFromCurrentDate);

        let lastPos = savingResults.initialAmounts.length - 1;

        return (
            <div className="results">
                <Container>
                    <Row>
                        <Col md={6} className="results summary-total">
                            <h5><strong>Your total
                                savings<br/> {getTotalSavingsDateString(labels[lastPos], this.props.investmentLengthUnit)}</strong>
                            </h5>
                            <span
                                className="totalSavings">{formatAsCurrency(savingResults.totalSavings[lastPos], '$', 0)}</span>
                        </Col>
                        <Col md={6} className="results summary-breakdown">

                            <h6 className="text-center">Total savings breakdown</h6>
                            <Table>
                                <tbody>
                                <tr>
                                    <td>Initial amount</td>
                                    <td><strong>{formatAsCurrency(savingResults.initialAmounts[lastPos], '$', 0)}</strong></td>

                                </tr>
                                <tr>
                                    <td>Contributions</td>
                                    <td><strong>{formatAsCurrency(savingResults.contributions[lastPos], '$', 0)}</strong></td>

                                </tr>
                                <tr>
                                    <td>Interest</td>
                                    <td><strong>{formatAsCurrency(savingResults.interest[lastPos], '$', 0)}</strong></td>

                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row className="results chart">
                        <Col>
                            <SavingsCalculatorBarChart
                                initialAmounts={savingResults.initialAmounts}
                                contributions={savingResults.contributions}
                                interest={savingResults.interest}
                                totalSavings={savingResults.totalSavings}
                                labels={labels}
                                investmentLengthUnit={this.props.investmentLengthUnit}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
