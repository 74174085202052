import React from 'react';
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

export default function CardComponent(props) {
  return (
    <>
      <Card>
        {/*<Card.Img variant="top" src="holder.js/100px160" />*/}
        <Card.Body>
          <Card.Title>
            {props.location && <a href={props.location}>{props.title}</a>}
            {!props.location && <>{props.title}</>}
          </Card.Title>
          <Card.Text>{props.text}</Card.Text>
        </Card.Body>
        <Card.Footer>
          <small className="text-muted">{props.footer}</small>
        </Card.Footer>
      </Card>
    </>
  );
};