import React from 'react';
import Chart from 'chart.js';
import {getXLabel, numberWithCommas} from './utils.js';

export default class SavingsCalculatorBarChart extends React.Component {

    myChart;

    drawChart(initialAmounts, contributions, interest, totalSavings, labels, investmentLengthUnit) {
        const node = this.node;
        this.myChart = new Chart(node, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [
                    // {
                    //     type: 'line',
                    //     borderColor: 'rgb(187, 23, 65)',
                    //     borderWidth: 2,
                    //     fill: false,
                    //     hidden: false,
                    //     label: 'Total savings',
                    //     data: totalSavings
                    // },
                    {
                        type: 'bar',
                        backgroundColor: 'rgba(23, 12, 132, 1)',
                        borderColor: 'rgba(23, 12, 132, 1)',
                        data: initialAmounts,
                        label: 'Initial amount'
                    }, {
                        type: 'bar',
                        backgroundColor: 'rgba(255, 99, 132, 1)',
                        borderColor: 'rgba(255, 99, 132, 1)',
                        data: contributions,
                        label: 'Contributions'
                    }, {
                        type: 'bar',
                        backgroundColor: 'rgba(54, 162, 235, 1)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        data: interest,
                        label: 'Interest'
                    }]
            },
            options: {
                tooltips: {
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        label: function (tooltip) {
                            return "$" + numberWithCommas(Math.round(tooltip.yLabel));
                        },
                        footer: function (tooltipItems, data) {
                            let total = 0;
                            for (let i = 0; i < tooltipItems.length; i++) {
                                total += parseInt(tooltipItems[i].yLabel, 10);
                            }
                            return 'Total savings: $' + numberWithCommas(Math.round(total));
                        }
                    }
                },
                scales: {
                    xAxes: [{
                        stacked: true,
                        scaleLabel: {
                            display: true,
                            labelString: getXLabel(investmentLengthUnit)
                        }
                    }],
                    yAxes: [{
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                            // Include a dollar sign in the ticks
                            callback: function (value, index, values) {
                                return '$' + numberWithCommas(value);
                            }
                        }
                    }]
                }
            }
        });
    }

    componentDidMount() {
        this.drawChart(this.props.initialAmounts, this.props.contributions, this.props.interest,
            this.props.totalSavings, this.props.labels,
            this.props.investmentLengthUnit);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // It's important to call destroy() to avoid the issues described at https://bit.ly/2Y3VHHv
        this.myChart.destroy();
        this.drawChart(this.props.initialAmounts, this.props.contributions, this.props.interest,
            this.props.totalSavings, this.props.labels, this.props.investmentLengthUnit);
    }

    render() {
        return (
            <canvas width={100} height={60} ref={node => (this.node = node)}></canvas>
        );
    }
}
