import React from 'react';
import {Navbar, Nav} from 'react-bootstrap';
import Image from "react-bootstrap/Image";
import logo from './../../../img/logo_wisehamster.png';

class Navigation extends React.Component {
  render() {
    return (
      <>
        <div className="warning-msg">This website is under intense development. Visit us frequently for more amazing content!</div>
        <Navbar className="App-header">
          {/*<Navbar.Brand href="/">WiseHamster</Navbar.Brand>*/}
          <Navbar.Brand>
            <a href="/">
              <Image src={logo} style={{width: 200, marginTop: -7}}/>
            </a>
          </Navbar.Brand>
          <Nav className="mr-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/savings-calculator">Savings Calculator</Nav.Link>
            {/*<Nav.Link href="#section2">Section2</Nav.Link>*/}
          </Nav>
        </Navbar>
      </>
    )
  }
}

export default Navigation;