import React from 'react';

export default class Footer extends React.Component {
    render() {
        return (
            <footer className="footer">
                <div className="py-4">© 2020 Copyright:&nbsp;<a href="">M2R</a>
                </div>
            </footer>
        )
    }
}